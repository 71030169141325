import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../../Static/Style/ChannelCss/ChannelList.css';
import channelProfile from '../../assets/user.png';
import { useChannelStore } from '../../Store/UserStore/ChannelStore';
import UpdateChannel from './UpdateChannel';
import { mediaUrl } from '../../Config';

const ChannelList = () => {
    const { userChannels, deleteChannel } = useChannelStore();
    const [visibleOptionsId, setVisibleOptionsId] = useState(null);
    const [selectedChannel, setSelectedChannel] = useState(null);
    const optionsRef = useRef(null);
    const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false); 
    const [channelToDelete, setChannelToDelete] = useState(null);

    const toggleOptions = (id) => {
        setVisibleOptionsId(prevId => (prevId === id ? null : id));
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (optionsRef.current && !optionsRef.current.contains(event.target)) {
                setVisibleOptionsId(null);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const openDeleteModal = (channelId) => {
        setChannelToDelete(channelId);
        setIsDeleteModalOpen(true); 
        setVisibleOptionsId(null); 
    };

    const closeDeleteModal = () => {
        setIsDeleteModalOpen(false);
        setChannelToDelete(null);
    };

    const confirmDeleteChannel = async () => {
        try {
            await deleteChannel(channelToDelete);
            setIsDeleteModalOpen(false);
            window.location.reload();
        } catch (error) {
            console.error("Error deleting channel:", error);
        }
    };

    const openUpdateChannel = (channel) => {
        setSelectedChannel(channel);
        setIsUpdateModalOpen(true);
        setVisibleOptionsId(null);
    };

    const closeUpdateChannel = () => {
        setIsUpdateModalOpen(false);
        setSelectedChannel(null);
    };

    const sortedChannels = [...userChannels].sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));


    return (
        <div className="channel-list-container">
            <div className="channel-lists">
                <h2>My Channels</h2>
                <div className="channel-grid">
                    {sortedChannels && sortedChannels.length > 0 ? (
                        sortedChannels.map((channel) => (
                            <div className="channel-item" key={channel.id} style={{ position: 'relative' }}>
                                <div className='yu-3dots-list-icon'>
                                    <span
                                        className='yu-3-dots-icon'
                                        onClick={() => toggleOptions(channel.id)}
                                    ></span>

                                    {visibleOptionsId === channel.id && (
                                        <div className='yu-3dots-list-options' ref={optionsRef}>
                                            <span onClick={() => openUpdateChannel(channel)}>Edit</span>
                                            <span onClick={() => openDeleteModal(channel.id)}>Delete</span>
                                        </div>
                                    )}
                                </div>

                                <div className="channel-thumbnail">
                                    <img
                                        src={mediaUrl+`${channel.profile}`|| channelProfile}
                                        alt={`${channel.name} Thumbnail`}
                                    />
                                </div>
                                <div className="channel-details">
                                    <h3>{channel.name}</h3>
                                    <p className="channel-description">{channel.description}</p>
                                    <p className="channel-subscribers">
                                        <span>0</span> subscribers
                                    </p>
                                    <div className="channel-actions">
                                        <Link
                                            to={`/channel/${channel.name}/${channel.id}/`}
                                            className="manage-button"
                                            // state={{ description: channel.description, channelId: channel.id }}
                                        >
                                            Manage
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        ))
                    ) : (
                        <p>No channels available.</p>
                    )}
                </div>
            </div>

            {/* Update Channel Modal */}
            {isUpdateModalOpen && (
                <UpdateChannel 
                    channel={selectedChannel} 
                    onClose={closeUpdateChannel} 
                />
            )}

            {/* Delete Confirmation Modal */}
            {isDeleteModalOpen && (
                <div className="yu-delete-modal">
                    <div className="delete-modal-content">
                        <h3>Are you sure you want to delete this channel?</h3>
                        <div className="delete-modal-actions">
                            <button className="delete-confirm-button" onClick={confirmDeleteChannel}>Yes</button>
                            <button className="delete-cancel-button" onClick={closeDeleteModal}>No</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ChannelList;
