import React, { useEffect, useRef } from 'react';
import '../../Static/Style/UploadVideosCss/UploadNotify.css';

const UploadingNotify = ({ onProgress = 0, onClose }) => {
  const popupRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  return (
    <div className='upload-progress-con' ref={popupRef}>
      <h4>Video Upload Status</h4>
      {onProgress > 0 && (
        <div className="upload-progress-bar">
          <div className="progress" style={{ width: `${onProgress}%` }}>
            {onProgress}%
          </div>
        </div>
      )}
      <div className="uploaded-files">
        {onProgress < 100 ? (
          onProgress > 0 ? (
            <div>Video Uploading... {onProgress}%</div> 
          ) : (
            <div>No upload started.</div>
          )
        ) : (
          <div>Upload Complete!</div>
        )}
      </div>
      <div className="actions">
        <button className="cancel-btn" onClick={onClose}>
          <span className='yu-app-cross-icon'></span>
        </button>
      </div>
    </div>
  );
};

export default UploadingNotify;
