import React, { useState } from 'react';
import '../../Static/Style/UploadVideosCss/SingleVideo.css';
import { useVideoStore } from '../../Store/UserStore/VideoStore';
import { useAccountStore } from '../../Store/UserStore/AccountStore';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BlobServiceClient, Credential } from '@azure/storage-blob';
// import { Buffer } from 'buffer';

// window.Buffer = Buffer;


const UploadVideo = ({ onClose }) => {
  const { uploadVideo, getAllVideos, getSasToken } = useVideoStore();
  const { currentUser } = useAccountStore();

  const [formData, setFormData] = useState({
    videoFile: null,
    thumbnailFile: null,
    title: '',
    description: '',
    tags: '',
    category: '',
    privacy: 'public',
  });

  const [previewUrl, setPreviewUrl] = useState('');
  const [uploadProgress, setUploadProgress] = useState(0);
  const maxChars = 900;

  const handleFileChange = (e) => {
    const { id, files } = e.target;
    const file = files[0];

    const allowedVideoExtensions = ['.mp4', '.avi', '.mkv', '.mov', '.wmv'];

    if (id === 'videoFile' && file) {
      const fileExtension = file.name.slice(((file.name.lastIndexOf(".") - 1) >>> 0) + 2);

      if (!allowedVideoExtensions.includes('.' + fileExtension.toLowerCase())) {
        toast.error('Invalid file type. Please upload a video file (MP4, AVI, MKV, MOV, WMV).');
        e.target.value = '';
        return;
      }

      setFormData((prevData) => ({ ...prevData, [id]: file }));
      setPreviewUrl(URL.createObjectURL(file));
    } else {
      setFormData((prevData) => ({ ...prevData, [id]: file }));
    }
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [id]: value }));
  };

  const handleDescriptionChange = (e) => {
    const { value } = e.target;
    if (value.length <= maxChars) {
      setFormData((prevData) => ({ ...prevData, description: value }));
    }
  };

  const getCSRFToken = () => {
    let cookieValue = null;
    const name = 'csrftoken';
    if (document.cookie && document.cookie !== '') {
      const cookies = document.cookie.split(';');
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.substring(0, name.length + 1) === (name + '=')) {
          cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
          break;
        }
      }
    }
    return cookieValue;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.videoFile) {
      toast.error('Select a video file.');
      return;
    }
    if (!formData.title) {
      toast.error('Provide a title.');
      return;
    }
    const userID = currentUser.id;
    const ChannelID = localStorage.getItem('selectedId');
    if (!ChannelID) {
      toast.error('Please Select Channel.');
      return;
    }
    const data = new FormData();
    if (formData.thumbnailFile) {
      data.append('thumbnail', formData.thumbnailFile);
    }
    data.append('name', formData.title);
    data.append('description', formData.description);
    data.append('tags', formData.tags);
    data.append('category', formData.category);
    data.append('video_type', formData.privacy);
    data.append('user', userID);
    data.append('channel', ChannelID);
    
    const sasResponse = await getSasToken({ blob_name: formData.videoFile.name });

    if (sasResponse.status !== 200) {
      throw new Error('Failed to get SAS token.');
    }

    const { sas_token, blob_url, blob_name } = sasResponse.data;

    // Inside your upload function
    const blobServiceClient = new BlobServiceClient(`${blob_url}?${sas_token}`);
    const containerClient = blobServiceClient.getContainerClient('videos/videos');
    const blockBlobClient = containerClient.getBlockBlobClient(blob_name);
    toast.info('Uploading started...');
    onClose();

    const response = await blockBlobClient.uploadBrowserData(formData.videoFile, {
      blobHTTPHeaders: {
        blobContentType: formData.videoFile.type
      }
    })
    if (response._response.status===201) {
      data.append('video',blob_url+'videos/videos/'+blob_name)

     uploadVideo(data, {
  onUploadProgress: (progressEvent) => {
    if (progressEvent.lengthComputable) {
      const percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      setUploadProgress(percentCompleted);
    }
  }
})
  .then((res) => {
    toast.success('Video uploaded successfully!');
    getAllVideos(); 
  })
      .catch(error=>{
        const errorMessage = error.response && error.response.data
            ? error.response.data
            : error.message;
    
          toast.error(`Error: ${errorMessage}`);
      })
    }


    // try {

    //   toast.info('Uploading started...');
    //   onClose();

    //   const uploadVideoResponse = await uploadVideo(data, (progressEvent) => {
    //     if (progressEvent.lengthComputable) {
    //       const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
    //       setUploadProgress(percentCompleted);
    //     }
    //   });
    //   toast.success('Video uploaded successfully!');
    //   getAllVideos();
    // } catch (error) {
    //   console.error('Upload error:', error);
    //   const errorMessage = error.response && error.response.data
    //     ? error.response.data
    //     : error.message;

    //   toast.error(`Error: ${errorMessage}`);
    // }
  };

  const currentCharCount = formData.description.length;
  const remainingChars = maxChars - currentCharCount;

  return (
    <>
      <div className="yu-upload-popup-overlay"></div>
      <div className="yu-upload-popup">
        <h2>Upload Video</h2>
        <form onSubmit={handleSubmit} encType="multipart/form-data">
          <div className="yu-upload-form-group">
            {uploadProgress > 0 && (
              <div className="progress-overlay">
                <div className="progress-bar" style={{ width: `${uploadProgress}%` }}></div>
              </div>
            )}
          </div>
          <div className="yu-upload-form-group">
            <label htmlFor="videoFile">Select Video File</label>
            <input type="file" id="videoFile" accept="video/*" onChange={handleFileChange} />
          </div>
          <div className="yu-upload-form-group">
            <label htmlFor="thumbnailFile">Select Video Thumbnail</label>
            <input type="file" id="thumbnailFile" accept="image/*" onChange={handleFileChange} />
          </div>
          <div className="yu-upload-form-group">
            <label htmlFor="title">Video Title</label>
            <input
              type="text"
              id="title"
              placeholder="Enter video title"
              value={formData.title}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="yu-upload-form-group">
            <label htmlFor="description">Video Description</label>
            <textarea
              id="description"
              placeholder="Add a description for your video"
              value={formData.description}
              onChange={handleDescriptionChange}
              maxLength={maxChars}
              required
            ></textarea>
            <div className={`char-count ${remainingChars < 0 ? 'red' : ''}`}>
              {remainingChars < 0 ? `-${Math.abs(remainingChars)}` : remainingChars}
            </div>
          </div>
          <div className="yu-upload-form-group">
            <label htmlFor="tags">Tags (comma separated)</label>
            <input
              type="text"
              id="tags"
              placeholder="Enter tags separated by commas"
              value={formData.tags}
              onChange={handleInputChange}
            />
          </div>
          <div className="yu-upload-form-group">
            <label htmlFor="category">Category</label>
            <input
              type="text"
              id="category"
              placeholder="Enter category separated by commas"
              value={formData.category}
              onChange={handleInputChange}
            />
          </div>
          <div className="yu-upload-form-group">
            <label htmlFor="privacy">Video Privacy</label>
            <select id="privacy" value={formData.privacy} onChange={handleInputChange}>
              <option value="public">Public</option>
              <option value="private">Private</option>
            </select>
          </div>
          <div className="yu-upload-form-group-button">
            <button type="button" className="close-button" onClick={onClose}>
              Cancel
            </button>
            <button type="submit">Upload Video</button>
          </div>
        </form>
      </div>
    </>
  );
};

export default UploadVideo;
