import React, { useState } from 'react';
import '../../Static/Style/UploadVideosCss/MultiVideos.css';
import { useVideoStore } from '../../Store/UserStore/VideoStore';
import { useChannelStore } from '../../Store/UserStore/ChannelStore';
import { useAccountStore } from '../../Store/UserStore/AccountStore';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const MultiUploadVideos = ({ onClose }) => {
  const { uploadVideo } = useVideoStore();
  const { currentUser } = useAccountStore();
  const { currentChannel } = useChannelStore();

  const [videos, setVideos] = useState([
    {
      videoFile: null,
      thumbnailFile: null,
      title: '',
      description: '',
      tags: '',
      category: '',
      privacy: 'public',
      uploadProgress: 0,
    },
  ]);

  const handleFileChange = (index, e) => {
    const { id, files } = e.target;
    const file = files[0];
    const updatedVideos = [...videos];
    updatedVideos[index][id] = file;
    setVideos(updatedVideos);
  };

  const handleInputChange = (index, e) => {
    const { id, value } = e.target;
    const updatedVideos = [...videos];
    updatedVideos[index][id] = value;
    setVideos(updatedVideos);
  };

  const handleAddVideo = () => {
    setVideos([
      ...videos,
      {
        videoFile: null,
        thumbnailFile: null,
        title: '',
        description: '',
        tags: '',
        category: '',
        privacy: 'public',
        uploadProgress: 0,
      },
    ]);
  };

  const handleRemoveVideo = (index) => {
    if (videos.length === 1) return; 
    const updatedVideos = videos.filter((_, i) => i !== index);
    setVideos(updatedVideos);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    for (const [index, video] of videos.entries()) {
      if (!video.videoFile) {
        toast.error(`Select a video file for video ${index + 1}.`);
        return;
      }
      if (!video.title) {
        toast.error(`Provide a title for video ${index + 1}.`);
        return;
      }

      const userID = currentUser.id;
      const ChannelID = currentChannel?.id;

      if (!ChannelID) {
        toast.error('Create channel first.');
        return;
      }

      const data = new FormData();
      data.append('video', video.videoFile);
      if (video.thumbnailFile) {
        data.append('thumbnail', video.thumbnailFile);
      }
      data.append('name', video.title);
      data.append('description', video.description);
      data.append('tags', video.tags);
      data.append('category', video.category);
      data.append('video_type', video.privacy);
      data.append('user', userID);
      data.append('channel', ChannelID);

      try {
        // const response = await uploadVideo(data, (progressEvent) => {
        //   if (progressEvent.lengthComputable) {
        //     const percentCompleted = Math.round(
        //       (progressEvent.loaded * 100) / progressEvent.total
        //     );
        //     const updatedVideos = [...videos];
        //     updatedVideos[index].uploadProgress = percentCompleted;
        //     setVideos(updatedVideos);
        //   }
        // });
        console.log(`Video ${index + 1} Uploaded`);
        toast.success(`Video ${index + 1} uploaded successfully!`);
      } catch (error) {
        console.error(`Upload error for video ${index + 1}:`, error);
        toast.error(`Error uploading video ${index + 1}: ${error.message}`);
      }
    }

    onClose();
  };

  return (
    <>
      <div className="yu-upload-popup-overlay"></div>
      <div className="yu-multi-upload-popup">
        <h2>Upload Videos</h2>
        <form onSubmit={handleSubmit} encType="multipart/form-data">
          {videos.map((video, index) => (
            <div key={index} className="yu-multi-upload-form">
              <h3>Video {index + 1}</h3>
          
              {video.uploadProgress > 0 && (
                <div className="progress-overlay">
                  <div
                    className="progress-bar"
                    style={{ width: `${video.uploadProgress}%` }}
                  ></div>
                </div>
              )}
          
              <div className="yu-multi-upload-row">
                <div className="yu-multi-upload-form-group">
                  <label htmlFor={`videoFile-${index}`}>Video File</label>
                  <input
                    type="file"
                    id="videoFile"
                    accept="video/*"
                    onChange={(e) => handleFileChange(index, e)}
                  />
                </div>
          
                <div className="yu-multi-upload-form-group">
                  <label htmlFor={`thumbnailFile-${index}`}>Thumbnail</label>
                  <input
                    type="file"
                    id="thumbnailFile"
                    accept="image/*"
                    onChange={(e) => handleFileChange(index, e)}
                  />
                </div>
          
                <div className="yu-multi-upload-form-group">
                  <label htmlFor={`title-${index}`}>Title</label>
                  <input
                    type="text"
                    id="title"
                    placeholder="Enter video title"
                    value={video.title}
                    onChange={(e) => handleInputChange(index, e)}
                  />
                </div>
                <div className="yu-multi-upload-form-group">
                  <label htmlFor={`description-${index}`}>Description</label>
                  <input
                    type="text"
                    id="title"
                    placeholder="Video Description (optional)"
                    value={video.title}
                    onChange={(e) => handleInputChange(index, e)}
                  />
                </div>
                <div className="yu-multi-upload-form-group">
                  <label htmlFor={`category-${index}`}>Category</label>
                  <input
                    type="text"
                    id="title"
                    placeholder="Enter category"
                    value={video.title}
                    onChange={(e) => handleInputChange(index, e)}
                  />
                </div>
                <div className="yu-multi-upload-form-group">
                  <label htmlFor={`tags-${index}`}>Tags</label>
                  <input
                    type="text"
                    id="tags"
                    placeholder="Enter tags"
                    value={video.title}
                    onChange={(e) => handleInputChange(index, e)}
                  />
                </div>
          
                <div className="yu-multi-upload-form-group">
                  <label htmlFor={`privacy-${index}`}>Privacy</label>
                  <select
                    id="privacy"
                    value={video.privacy}
                    onChange={(e) => handleInputChange(index, e)}
                  >
                    <option value="public">Public</option>
                    <option value="private">Private</option>
                  </select>
                </div>
          
                <div className="yu-multi-upload-form-group-button">
                  {videos.length > 1 && (
                    <button
                      className="remove-button"
                      onClick={() => handleRemoveVideo(index)}
                    >
                      Remove
                    </button>
                  )}
                </div>
              </div>
            </div>
          ))}
                    <div className="yu-multi-upload-form-group-button">
                      <div>
            <button type="button" className="add-button" onClick={handleAddVideo}>
              Add
            </button>
            </div>
            <div>
            <button type="button" className="close-button" onClick={onClose}>
              Cancel
            </button>
            <button type="submit">Upload</button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default MultiUploadVideos;
