import React, { useState, useRef, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Auth from '../Auth/Auth';
import '../../Static/Style/YuRightComponent.css';
import CreateChannel from '../Channel/CreateChannel';
import { useAccountStore } from '../../Store/UserStore/AccountStore';
import { toast } from 'react-toastify';
import { useChannelStore } from '../../Store/UserStore/ChannelStore';
import UploadVideo from '../UploadVideos/UploadVideo';

const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
};

const getInitials = (name) => {
    if (!name) return '';
    const names = name.split(' ');
    return names.length > 1 ? `${names[0][0]}${names[1][0]}` : names[0][0];
};

const YuRightComponent = ({ onProfileImageChange }) => {
    const [isCreateChannelVisible, setIsCreateChannelVisible] = useState(false);
    const [isAuthPopupVisible, setIsAuthPopupVisible] = useState(false);
    const popupRef = useRef(null);
    const navigate = useNavigate();
    const [isUploadPopupVisible, setIsUploadPopupVisible] = useState(false);


    const { currentUser, isLoggedIn, userlogout } = useAccountStore();
    const { userChannels, getUserChannel } = useChannelStore();

    const [selectedChannel, setSelectedChannel] = useState(null);
    const [showChannelList, setShowChannelList] = useState(false);
    const [isOptionsVisible, setIsOptionsVisible] = useState(false);


    const openAuthPopup = () => {
        setIsAuthPopupVisible(true);
    };

    const handleCreateChannelClick = () => {
        if (!isLoggedIn) {
            toast.error("Please Login First");
            setTimeout(openAuthPopup, 200);
            return;
        }
        setIsCreateChannelVisible(true);
    };

    const handleCloseCreateChannel = () => {
        setIsCreateChannelVisible(false);
    };

    const handleClickOutside = (event) => {
        if (popupRef.current && !popupRef.current.contains(event.target)) {
            setIsCreateChannelVisible(false);
        }
    };

    const handleYourChannelClick = () => {
        if (!isLoggedIn) {
            toast.error('Please login first');
            setTimeout(openAuthPopup, 200);
            return;
        }
        navigate(`/channel-list/`);
    };

    const handleHomeClick = () => {
        navigate('/');
    };

    const handleChannelChangeClick = async () => {
        await getUserChannel();
        setShowChannelList(!showChannelList);
    };

    const handleChannelClick = (channel) => {
        setSelectedChannel(channel);
        localStorage.setItem('selectedId', channel.id);
        setShowChannelList(false);
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        const fetchChannels = async () => {
            if (isLoggedIn) {
                try {
                    await getUserChannel();
                    const savedChannelId = localStorage.getItem('selectedId');
                    console.log('Saved Channel ID:', savedChannelId);
                        if (savedChannelId && userChannels.length > 0) {
                        const savedChannel = userChannels.find(channel => channel.id.toString() === savedChannelId);
                        console.log('Saved Channel:', savedChannel);
                        setSelectedChannel(savedChannel || null);
                    }
                } catch (error) {
                    console.error('Error fetching channels:', error);
                    toast.error('Failed to fetch channels. Please try again later.');
                }
            }
        };
        fetchChannels();
    }, [isLoggedIn, getUserChannel]);
    

    useEffect(() => {
        if (userChannels.length > 0) {
            const savedChannelId = localStorage.getItem('selectedId');
            const savedChannel = userChannels.find(channel => channel.id.toString() === savedChannelId);
            if (savedChannel) {
                setSelectedChannel(savedChannel);
            } else {
                setSelectedChannel(null);
            }
        }
    }, [userChannels]);

    const getProfileColor = () => {
        const storedColor = localStorage.getItem(`userColor-${currentUser?.id}`);
        if (storedColor) {
            return storedColor;
        }
        const newColor = getRandomColor();
        localStorage.setItem(`userColor-${currentUser?.id}`, newColor);
        return newColor;
    };

    const profileImage = currentUser?.profile
        ? currentUser.profile
        : `https://ui-avatars.com/api/?name=${getInitials(currentUser?.name)}&background=${getProfileColor().replace('#', '')}&color=fff&rounded=true&size=128`;

    useEffect(() => {
        if (currentUser) {
            onProfileImageChange(profileImage, isLoggedIn);
        }
    }, [profileImage, isLoggedIn, onProfileImageChange, currentUser]);


    const toggleOptionsVisibility = () => {
      setIsOptionsVisible(!isOptionsVisible);
    };

    const handleNavigateChannel = async () => {
        navigate(`/channel/${selectedChannel.name}/${selectedChannel.id}/`);
    };
    const openUploadPopup = () => {
        if (!isLoggedIn) {
          toast.error('Please Login First');
          return;
        }
        setIsUploadPopupVisible(true);
      };

    return (
        <>
            <div className='yu-right-side'>
                <div className='yu-right-items'>
                    {isLoggedIn && currentUser ? (
                        <div className="yu-user-info">
                            <img src={profileImage} alt="User Profile" className="profile-image" />
                            <p className='yu-user-info-name'>{currentUser.name}</p>
                            <p className='yu-user-info-userName'>{currentUser.email}</p>
                         <p className='yu-user-info-channel-name'>
         <div>  

    {selectedChannel ? (
        <p>{selectedChannel.name}</p>
    ) : (
        <span style={{ color: "var(--red)" }}>Select channel</span>
    )}
    
    <span title={selectedChannel ? "Change channel" : "Select channel"} onClick={handleChannelChangeClick}>
        {selectedChannel ? "Change" : "Select"}
    </span> 
    </div>
    {selectedChannel && (
    <p className="yu-user-manage-channel" onClick={handleNavigateChannel}>Manage Channel</p>
)}
    {showChannelList && (
        <div className='yu-channel-name-list'>
            {userChannels && userChannels.length > 0 ? (
                userChannels.map((channel) => (
                    <p
                        key={channel.id}
                        onClick={() => handleChannelClick(channel)}
                        style={{ 
                            color: channel.id === selectedChannel?.id ? 'var(--white)' : 'var(--subtitle)',
                            backgroundColor: channel.id === selectedChannel?.id ? 'var(--buttonColor)' : 'var(--white)',
                        }}
                    >
                        {channel.name}
                    </p>
                ))
            ) : (
                <p>No channels available</p>
            )}
        </div>
    )}
                            </p>

                            <div className='yu-user-logout-div'>
                                <button className="yu-user-logout" title='User Logout' onClick={userlogout}>
                                    <span className='user-logout-icon'></span>Logout
                                </button>
                            </div>
                        </div>
                    ) : (
                        <h5 className='login-yu-icon' title='Login' onClick={openAuthPopup}>
                            <span className='login-user-icon'></span>Login
                        </h5>
                    )}
                    <h5 onClick={handleHomeClick}><span className='yu-home-icon'></span>Home</h5>

                    <div>
      <h5 className='yu-upload-videos' onClick={toggleOptionsVisibility}>
        <span className='yu-upload-video-icon'></span>
        Upload Videos
      </h5>
      {isOptionsVisible && (
        <div>
          <div className='yu-upload-video-options'>
            <p onClick={openUploadPopup}> -Upload Single Video</p>
            {/* <p> -Upload Multiple Videos</p> */}

          </div>
          <div>
          </div>
        </div>
      )}
    </div>
                    <h5 onClick={handleYourChannelClick}>
                        <span className='my-channel-icon'></span>My Channel
                    </h5>
                    <h5 onClick={handleCreateChannelClick}>
                        <span className='craete-channel-icon'></span>Create Channel
                    </h5>
                    <div className='yu-terms-policy'>
                        <span className='yu-terms-condition-policy'>
                            <Link to="/terms-conditions" target="_blank" rel="noopener noreferrer">
                                <li>Terms and Conditions</li>
                            </Link>
                        </span>
                        <span className='yu-terms-condition-policy'>
                            <Link to="/privacy-policy" target="_blank" rel="noopener noreferrer">
                                <li>YU-Policy</li>
                            </Link>
                        </span>
                    </div>
                </div>
            </div>

            {isCreateChannelVisible && (
                <>
                    <div className="modal-overlay"></div>
                    <div className='create-channel-modal' ref={popupRef}>
                        <button className='close-modal' onClick={handleCloseCreateChannel}><span className='yu-app-cross-icon'></span></button>
                        <CreateChannel onClose={handleCloseCreateChannel} />
                    </div>
                </>
            )}

            {isAuthPopupVisible && (
                <Auth onClose={() => setIsAuthPopupVisible(false)} />
            )}

{isUploadPopupVisible && (
        <UploadVideo onClose={() => setIsUploadPopupVisible(false)} />
      )}
      
        </>
    );
};

export default YuRightComponent;
