import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../Static/Style/CreateChannel.css';
import { useChannelStore } from '../../Store/UserStore/ChannelStore';
import { useAccountStore } from '../../Store/UserStore/AccountStore';
import { toast } from 'react-toastify';

const CreateChannel = ({ onClose }) => {
    const [channelName, setChannelName] = useState('');
    const [channelDescription, setChannelDescription] = useState('');
    const [loading, setLoading] = useState(false); 
    const navigate = useNavigate();
    const { createChannel } = useChannelStore();
    const { currentUser } = useAccountStore();
    const [profileImageFile, setProfileImageFile] = useState(null); 
    const [profileImagePreview, setProfileImagePreview] = useState(null);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setProfileImageFile(file);
            const reader = new FileReader();
            reader.onloadend = () => {
                setProfileImagePreview(reader.result);
            };
            reader.readAsDataURL(file); 
        }
    };

    const handlePreviewClick = () => {
        document.getElementById('channelProfile').click(); 
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        if (!channelName) {
            toast.error('Please provide a channel name.');
            return;
        }
        if (!channelDescription) {
            toast.error('Please provide a channel description.');
            return;
        }
        if (!currentUser?.id) {
            toast.error('User is not logged in.');
            return;
        }
    
        const userID = currentUser.id;

        const formData = new FormData();
        formData.append('name', channelName);
        formData.append('description', channelDescription);
        formData.append('user', userID);

        if (profileImageFile) {
            formData.append('profile', profileImageFile); 
        }

        setLoading(true);
        try {
            const response = await createChannel(formData);
            toast.success('Channel created successfully!');
            onClose(); 
        } catch (error) {
            console.error('Channel creation error:', error);
            toast.error(`Error: ${error.message}`);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className='create-channel-form'>
            <h2>Create Channel</h2>
            <form onSubmit={handleSubmit}>

                <div className='create-channel-form-group'>
                    <div className='channel-profile-preview' onClick={handlePreviewClick}>
                        {profileImagePreview ? (
                            <img src={profileImagePreview} alt='Channel Preview' title='Click to select another profile' />
                        ) : (
                            <span>No image selected</span>
                        )}
                    </div>
                    <input
                        id='channelProfile'
                        type='file'
                        accept="image/*"
                        style={{ display: "none" }}
                        onChange={handleFileChange} 
                    />
                </div>

                <div className='create-channel-form-group'>
                    <label htmlFor='channelName'>Channel Name</label>
                    <input
                        type='text'
                        id='channelName'
                        value={channelName}
                        onChange={(e) => setChannelName(e.target.value)}
                        placeholder='Enter Channel Name'
                        required
                    />
                </div>

                <div className='create-channel-form-group'>
                    <label htmlFor='channelDescription'>Description</label>
                    <textarea
                        id='channelDescription'
                        value={channelDescription}
                        onChange={(e) => setChannelDescription(e.target.value)}
                        placeholder='Channel Description'
                        required
                    />
                </div>

                <button type='submit' disabled={loading}>
                    {loading ? 'Creating...' : 'Create Channel'}
                </button>
            </form>
        </div>
    );
};

export default CreateChannel;
