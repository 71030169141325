import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactPlayer from 'react-player';
import '../../Static/Style/yuVideos.css';
import { useVideoStore } from '../../Store/UserStore/VideoStore';
import { useChannelStore } from '../../Store/UserStore/ChannelStore';
import moment from 'moment';
import MyLoader from '../Loader/MyLoader';
import LoadingErrorPage from '../LoadingErrorPage/LoadingErrorPage';
import { mediaUrl } from '../../Config';

const YuVideos = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { getAllVideos, currentVideo } = useVideoStore();
  const { getSingleChannel } = useChannelStore();
  const [channelNames, setChannelNames] = useState({}); 

  const handleVideoClick = (videoId) => {
    navigate(`/video/${videoId}`);
  };

  useEffect(() => {
    const fetchAllVideos = async () => {
      try {
        const videoResponse = await getAllVideos();

        if (videoResponse && videoResponse.data) {
          const uniqueChannelIds = new Set(videoResponse.data.map(video => video.channel));
  
          const fetchedChannelNames = {};
          for (const channelId of uniqueChannelIds) {
            const channelResponse = await getSingleChannel(channelId);
            if (channelResponse && channelResponse.name) {
              fetchedChannelNames[channelId] = channelResponse.name;
            }
          }
          setChannelNames(fetchedChannelNames);

          console.log("Channels name:-----",fetchedChannelNames)
        }
        setLoading(false);
      } catch (error) {
        setError('Error fetching Videos');
        setLoading(false);
      }
    };
  
    fetchAllVideos();
  }, [getAllVideos, getSingleChannel]); 
  
  
  
  

  if (loading) {
    return <div><MyLoader/></div>;
  }

  if (error) {
    return <div><LoadingErrorPage/></div>;
  }

  const sortedVideos = [...currentVideo].sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));

  const placeholderImage = 'https://via.placeholder.com/300x200.png?text=Loading+Thumbnail';

  return (
    <div className='yu-videos'>
      <div className='yu-trending-videos'>
        <h4>Trending Videos</h4>
        <div className='yu-trending-videos-grid'>
          {sortedVideos.map(video => (
            <div
              key={video.id}
              className='yu-trending-video-item'
              onClick={() => handleVideoClick(video.id)}
            >
              <div className='yu-trending-video-player'>
                <ReactPlayer
                  url={video.video}
                  light={video.thumbnail ? mediaUrl+`${video.thumbnail}` : placeholderImage}
                  width='100%'
                  height='100%'
                  controls={false}
                  playing={false}
                />
              </div>
              <h5>{video.name.length > 80 ? `${video.name.slice(0, 75)}...` : video.name}</h5>
              <p>{channelNames[video.channel] || 'Unknown Channel'} • 0 views • {moment(video.timestamp).fromNow()}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default YuVideos;
