import React, { useState, useEffect, useRef } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import '../../Static/Style/yuSingleVideo.css';
import userIMG from "../../assets/user.png";
import YuVideoList from './YuVideoList';
import { useVideoStore } from '../../Store/UserStore/VideoStore';
import { useAccountStore } from '../../Store/UserStore/AccountStore';
import { useChannelStore } from '../../Store/UserStore/ChannelStore';
import {
    Player,
    ControlBar,
    ReplayControl,
    ForwardControl,
    CurrentTimeDisplay,
    TimeDivider,
    PlaybackRateMenuButton,
    VolumeMenuButton,
    BigPlayButton,
    LoadingSpinner,
    ClosedCaptionButton,
    ProgressControl

} from 'video-react';
import 'video-react/dist/video-react.css';
import MyLoader from '../Loader/MyLoader';
import LoadingErrorPage from '../LoadingErrorPage/LoadingErrorPage';
import { toast } from 'react-toastify';
import moment from 'moment';
import SharePopup from '../SharePopup/SharePopup';
import { useNavigate } from 'react-router-dom';
import { mediaUrl } from '../../Config'
const YuSingleVideo = ({ profileImage }) => {
    const { getSingleVideos, currentVideo, postVideoComment, postLikeVideo, saveVideo, getCommentVideo, videoLikeCount, videoDislikeCount, videoCommentCount } = useVideoStore();
    const { isLoggedIn } = useAccountStore();
    const { getSingleChannel, currentChannel, subscribeChannel, channelSubscribersCount } = useChannelStore();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { videoId } = useParams();
    const [likes, setLikes] = useState(0);
    const [likeStatus, setLikeStatus] = useState(null);
    // const [showComments, setShowComments] = useState(false);
    const [comments, setComments] = useState([]);
    const [newComment, setNewComment] = useState('');

    const [isExpanded, setIsExpanded] = useState(false);
    const [showSharePopup, setShowSharePopup] = useState(false);
    const [shareLink, setShareLink] = useState('');
    const [likeCount, setLikeCount] = useState(0)
    const [dislikeCount, setDisikeCount] = useState(0)
    const [commentCount, setCommentCount] = useState(0)
    const [subscribersCount, setSubscribersCount] = useState(0)
    const [source, setSource] = useState()
    const navigate = useNavigate()

    const videoPlayerRef = useRef(null);
    const [previousTime, setPreviousTime] = useState(0);
    // const [currentTime, setCurrentTime] = useState(0);






    const handleToggle = () => {
        setIsExpanded(!isExpanded);
    };

    useEffect(() => {
        const fetchSingleVideo = async () => {
            try {
                const videoResponse = await getSingleVideos(videoId);
                if (videoResponse?.data) {
                    var channelId = videoResponse.data.channel;

                    try {
                        const channelResponse = await getSingleChannel(channelId);
                        console.log('Channel Response:', channelResponse);
                    } catch (error) {
                        console.error('Error fetching channel:', error);
                    }
                }



                try {
                    const commentResponse = await getCommentVideo(videoId);
                    if (commentResponse) {
                        const sortedComments = commentResponse.sort(
                            (a, b) => new Date(b.timestamp) - new Date(a.timestamp)
                        );
                        setComments(sortedComments);
                        console.log("Current Video Comments:", sortedComments);
                    }
                } catch (error) {
                    console.error('Error fetching comments:', error);
                }

                try {
                    const likeCountResponse = await videoLikeCount(videoId);
                    setLikeCount(likeCountResponse.data.likes);
                    console.log("Video Like Count:", likeCountResponse);
                } catch (error) {
                    console.error('Error fetching like count:', error);
                }

                try {
                    const dislikeCountResponse = await videoDislikeCount(videoId);
                    setDisikeCount(dislikeCountResponse.data.dislikes);
                    console.log("Video Disike Count:", dislikeCountResponse);
                } catch (error) {
                    console.error('Error fetching Dislike count:', error);
                }

                try {
                    const commentCountResponse = await videoCommentCount(videoId);
                    setCommentCount(commentCountResponse.data.comments);
                    console.log("Video Comment Count:", commentCountResponse);
                } catch (error) {
                    console.error('Error fetching Comment count:', error);
                }

                try {

                    const SubscriberCountResponse = await channelSubscribersCount(channelId);
                    setSubscribersCount(SubscriberCountResponse.data.subscribers);
                    // console.log("channelSubscribersCount:", channelSubscribersCount);
                } catch (error) {
                    console.error('Error fetching channelSubscribers count:', error);
                }

                setLoading(false);
            } catch (error) {
                console.error('Error fetching video:', error);
                setError('Error fetching video');
                setLoading(false);
            }
        };

        fetchSingleVideo();
    }, [getSingleVideos, videoId, getSingleChannel, getCommentVideo, videoLikeCount]);


    useEffect(() => {
        setSource(mediaUrl + currentVideo.video);
    }, [currentVideo]);



    // useEffect(() => {
    //     const myPlayer = videoPlayerRef.current;

    //     if (myPlayer) {
    //         console.log("Player initialized after delay");

    //         myPlayer.on('timeupdate', () => {
    //             setPreviousTime(myPlayer.currentTime); 
    //         });

    //         const handleSeek = (event) => {
    //             const currentTime = myPlayer.currentTime; 
    //             console.log("previous: " + previousTime + " current: " + currentTime);
    //         };

    //         myPlayer.controlBar.progressControl.on('mousedown', handleSeek);

    //         return () => {
    //             myPlayer.controlBar.progressControl.off('mousedown', handleSeek);
    //         };
    //     }
    // }, []);




    if (loading) {
        return <div><MyLoader /></div>;
    }

    if (error) {
        return <div><LoadingErrorPage /></div>;
    }










    const handleLike = async () => {
        if (isLoggedIn) {
            try {
                const value = "1";
                const Likeresponse = await postLikeVideo(videoId, value);
                console.log('API Response (Like):', Likeresponse.data);

                if (Likeresponse.status === 200) {
                    setLikeStatus(1);
                    console.log('Setting likeStatus to 1');
                    setLikes((prevLikes) => prevLikes + (likeStatus === 1 ? 0 : 1));
                }
            } catch (error) {
                console.error('Failed to like the video:', error);
            }
        } else {
            toast.error("Please login first");
        }
    };

    const handleDislike = async () => {
        if (isLoggedIn) {
            try {
                const value = "-1";
                const DisLikeresponse = await postLikeVideo(videoId, value);

                if (DisLikeresponse.status === 200) {
                    setLikeStatus(-1);
                    setLikes((prevLikes) => prevLikes - (likeStatus === -1 ? 0 : 1));
                }
                console.log('Disliked video:', DisLikeresponse.data);
            } catch (error) {
                console.error('Failed to dislike the video:', error);
            }
        } else {
            toast.error("Please login first");
        }
    };


    const handleShare = () => {
        const videoLink = `${window.location.origin}/video/${videoId}`;
        setShareLink(videoLink);
        setShowSharePopup(true);
    };


    const handleSave = async () => {
        if (isLoggedIn) {
            try {
                const videoSave = await saveVideo({
                    video_id: videoId

                });

                console.log('Video saved successfully:', videoSave);

            } catch (error) {
                console.error('Failed to save video:', error.message);
                toast.error('Failed to save the video');
            }
        } else {
            toast.error("Please login first");
        }
    };



    const handlesubscribe = async () => {
        if (isLoggedIn) {
            const channelId = currentVideo.channel;
            console.log("Channel ID:", channelId);

            try {
                const subscribeRes = await subscribeChannel(channelId);
                console.log('Subscription response:', subscribeRes);
            } catch (error) {
                console.error('Failed to subscribe to channel:', error);
            }
        } else {
            toast.error("Please login first");
        }
    };



    const handleComment = async () => {
        if (isLoggedIn) {
            try {
                const commentResponse = await postVideoComment(videoId, newComment);
                console.log('API Response:', commentResponse);

                if (Array.isArray(commentResponse.comments)) {
                    console.log(commentResponse.comments);
                } else {
                    console.warn('Expected an array of comments, but got:', commentResponse.comments);
                }

                setNewComment('');
            } catch (error) {
                console.error('Failed to post comment:', error);
            }
        } else {
            toast.error('Please login first');
        }
    };

    const handleNavigateChannel = async () => {
        navigate(`/channel/${currentChannel.name}/${currentChannel.id}/`);
    };


    // const handleSeek = (event) => {
    //     if (videoPlayerRef.current) {
    //         const progressBar = event.target;
    //         const { offsetWidth, clientX } = progressBar;
    //         const percentage = (clientX - progressBar.getBoundingClientRect().left) / offsetWidth; 
    //         const newTime = percentage * videoPlayerRef.current.duration; 
    //         videoPlayerRef.current.seek(newTime); 
    //         setSeekTime(newTime); 
    //     }
    // };





    return (
        <>
            <div className='yu-single-video-container'>
                <div className='yu-single-video-all'>
                    <div className='yu-single-video'>
                        <div className='video-player'>
                            <div style={{ position: 'relative', height: '450px', objectFit: 'contain' }}>
                                <Player
                                    poster={mediaUrl + `${currentVideo.thumbnail}`}
                                    autoPlay={true}
                                    fluid={false}
                                    width={'100%'}
                                    height={450}
                                    // ref={videoPlayerRef} 
                                    src={currentVideo.video}
                                    onReady={() => console.log('Player is ready')}
                                    onPlay={() => console.log('Playing')}
                                    onLoadedMetadata={() => console.log('Metadata loaded')}
                                // onTimeUpdate={() => {
                                //     previousTime = videoPlayerRef.current.currentTime();
                                // }}
                                >
                                    <BigPlayButton position="center" />
                                    <LoadingSpinner />

                                    <ControlBar autoHide={true} className="">
                                        <ReplayControl seconds={10} order={1.1} />
                                        <ForwardControl seconds={30} order={1.2} />
                                        <CurrentTimeDisplay order={4.1} />
                                        <TimeDivider order={4.2} />
                                        <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} order={7.1} />
                                        <VolumeMenuButton disabled />
                                        <VolumeMenuButton vertical />
                                        <ClosedCaptionButton order={7} />
                                        <ProgressControl />

                                        {/* <button onClick={() => handleSeek(1800)}>Seek to 30 minutes</button> */}
                                    </ControlBar>
                                </Player>

                            </div>
                        </div>

                        <div className='video-details'>
                            <div className='yu-video-info'>
                                <h1>{currentVideo.name}</h1>
                                <div className='yu-channel-info'>
                                    <div className='yu-channel-info1'>
                                        <div className='yu-channel-info2' onClick={handleNavigateChannel} title={`/channel/${currentChannel.name}/${currentChannel.id}/`}
                                        >
                                            <img src={mediaUrl + `${currentChannel.profile}` || userIMG} alt="Channel" />
                                            <h4>{currentChannel.name}</h4>
                                        </div>
                                        <button className='channel-Subscribe-button' onClick={handlesubscribe}>{subscribersCount} Subscribe <span className='subscribe-bell-icon'></span></button>
                                    </div>

                                    <div className="video-actions">
                                        <span onClick={handleLike} className='like-action'>
                                            {likeStatus === 1 ? (
                                                <span className='yu-like-bold-icon' title='i unlike this'></span>
                                            ) : (
                                                <span className='yu-like-icon' title='i like this'></span>
                                            )}
                                            <span className='text'>{likeCount}</span>
                                        </span>
                                        <span onClick={handleDislike} className='like-action'>
                                            {likeStatus === -1 ? (
                                                <span className='yu-dislike-bold-icon' title='i undislike this'></span>
                                            ) : (
                                                <span className='yu-dislike-icon' title='i dislike this'></span>
                                            )}
                                            <span className='text'>{dislikeCount}</span>
                                        </span>
                                        <span onClick={handleShare} className='share-action'>
                                            <span className='yu-share-icon' title='Share Video'></span> <span className='text'></span>
                                        </span>
                                        <span onClick={handleSave} className='save-action'>
                                            <span className='yu-vidos-save-icon' title='Saved'></span> <span className='text'></span>
                                        </span>
                                    </div>
                                </div>

                                <div className='yu-video-info-description-box'>

                                    <h5> <span>0 Views </span>• <span>{moment(currentVideo.timestamp).format(' D MMMM,YYYY')}</span> • <span style={{ color: "var(--blue)" }}>#{currentVideo.tags}</span></h5>
                                    <h6>{currentVideo.name}</h6>

                                    <p className="description-text">
                                        {isExpanded
                                            ? currentVideo.description
                                            : currentVideo.description?.length > 100
                                                ? `${currentVideo.description.slice(0, 100)}...`
                                                : currentVideo.description
                                        }                                        <span onClick={handleToggle} style={{ margin: "0 10px", color: "var(--black)" }}>
                                            {isExpanded ? 'Less' : 'More'}
                                        </span>
                                    </p>

                                </div>
                            </div>
                        </div>

                        <div className='comment-container'>
                            <h3>{commentCount} Comments</h3>
                            <div className='info'>
                                <img src={isLoggedIn ? profileImage : userIMG} alt="user" />
                                <div className="add-comment">
                                    <textarea
                                        type="text"
                                        value={newComment}
                                        onChange={(e) => setNewComment(e.target.value)}
                                        placeholder="Add a comment..."
                                    />
                                    <button onClick={handleComment}>Comment</button>
                                </div>
                            </div>

                            <div className="comments-list">
                                {comments.map((comment) => (
                                    <div className="comment-item" key={comment.id}>
                                        <div className="info">
                                            <img src={comment.user.avatar || userIMG} alt={comment.user.name || "User"} />
                                            <div className='comment-info'>
                                                <div>
                                                    <h5>@{comment.user.name}</h5>
                                                    <span>{moment(comment.timestamp).fromNow()}</span>
                                                </div>
                                                <div className="comment-text">
                                                    <p>{comment.comment}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>

                        </div>
                    </div>
                    <div className='yu-video-list-container'>
                        <YuVideoList
                            videoId={videoId}
                        />
                    </div>
                </div>

                {showSharePopup && (
                    <SharePopup
                        link={shareLink}
                        onClose={() => setShowSharePopup(false)}
                    />
                )}
            </div>
        </>
    );
};

export default YuSingleVideo;

